
import React, { Suspense, useState, useEffect ,useRef } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import podData from "../../json/ProductsData.json";
import LoadingSpinner from '../../Components/loader/spinner';
import './macroindicatorsMain.css';
const Brand = React.lazy(() => import("../../Components/header/appheader"));
const Footer = React.lazy(() => import("../../Components/footer"));

export default function MacroIndicatorsMain() {
  const [categData, setCategData] = useState([]);
  const [productData, setProductData] = useState(null);
  const [isUnderDevelopment, setIsUnderDevelopment] = useState(false);
  const [visibleCategory, setVisibleCategory] = useState(null);
  const childDivRef = useRef(null);
  const parentDivRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // Function to determine if the screen is mobile-sized
  const checkMobileScreen = () => {
    setIsMobile(window.matchMedia('(max-width: 768px)').matches);
  };

  const fetchProductData = async () => {

      const data = podData;
      setProductData(data);
   
  };

  const getCategoryData = (cat) => {
    const filterCategory = productData?.[0]?.products.filter((item) =>
      item.category.includes(cat)
    ) || [];

    setCategData(filterCategory);
    setIsUnderDevelopment(filterCategory.length < 1);

    // Toggle visibility based on the selected category
    setVisibleCategory((prevCategory) => (prevCategory === cat ? null : cat));

    setTimeout(() => {
      childDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
    setIsVisible(true);
  };

  useEffect(() => {
    fetchProductData();
    checkMobileScreen();
    window.addEventListener('resize', checkMobileScreen);
    return () => window.removeEventListener('resize', checkMobileScreen);

  }, []);
  
  const scrollToTop = (cat) => {
   
    setTimeout(() => {
      parentDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 50);
     setIsVisible(false);
     setVisibleCategory((prevCategory) => (prevCategory === cat ? null : cat));
  };
  return (
    <Suspense fallback={<div className='text-center loadspinner'><LoadingSpinner /></div>}>
      <Container className="udp-hp-wrapper" maxWidth="false">
        <Brand />
        <div className="hp-body body-macro">
        <Grid container spacing={5} className="section-1">
            <Grid item className="left-box" sm={12} md={12} lg={6}>
            <Typography variant="h2">MACRO INDICATORS</Typography>
            <p>
                Macro-indicators aims to provide access to official statistical products in an attempt to facilitate data usability and promote use of statistics in public. 
                <br></br><br></br>
                The portal has been developed with the objective of providing timely, credible, and user-friendly time series data of major indicators of official statistics.
            </p>
                
					
            </Grid>
            <Grid item className="right-box anim-text image-cloud" sm={12} md={12} lg={6}>
													   
																  
            </Grid>
           
          </Grid>
          {/* <Grid container spacing={4} className="section-1 macro-top">
            <Grid item className="macro-heading" sm={12} md={3} lg={3}>
              <Typography variant="h2">MACRO INDICATORS</Typography>
            </Grid>
            <Grid item className="macro-content" sm={12} md={9} lg={9}>
              <p>
                Macro-indicators aims to provide access to official statistical products in an attempt to facilitate data usability and promote use of statistics in public. The portal has been developed with the objective of providing timely, credible, and user-friendly time series data of major indicators of official statistics.
              </p>
            </Grid>
          </Grid> */}

          <Grid container className="section-2 macro2" ref={parentDivRef}>
            {productData?.[0]?.theme.map((item, index) => (
              <Grid
                item
                className="theme-wrap grid"
                xs={12} sm={12} md={6} lg={6}
               
              >
                <div className="theme-pods">
                  <figure className="effect-dexter"  onClick={() => getCategoryData(item.category)}
                    key={index}>
                    <figcaption>
                      <h5><span>{item.name}</span></h5>
                      <p>Click to see products</p>
                    </figcaption>
                  </figure>
                </div>
              </Grid>
            ))}
          </Grid>

          {visibleCategory && (
            <Grid container className="macropod-dropdown" ref={childDivRef}>
              <Grid container className="drop-container" spacing={2}>
                {categData.length > 0 ? (
                  categData.map((item, index) => (
                    <Grid item className="macrodropdown-wrap macropod-wrap" xs={12} sm={12} md={4} lg={4} key={index}>
                      <Link to={item.url} title={`Click to View ${item.product} Macro Indicators`} target="_blank">
                        <div className="macropod">
                          <h5>{item.title}</h5>
                        </div>
                      </Link>
                    </Grid>
                  ))
                ) : (
                  <Grid item className="macrodropdown-wrap macropod-wrap" xs={12} sm={12} md={3} lg={3}>
                    <div className="macropod">
                      <h5>Under Development</h5>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
           {isMobile && isVisible && (
            <div className="scroll-top-container">
              <button onClick={scrollToTop} className="scroll-top">
                ↑ Back to Top
              </button>
            </div>
          )}
        </div>
        <Footer />
      </Container>
    </Suspense>
  );
}
