import React, { Suspense, useState, useEffect } from "react";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import podData from "../../json/ProductsData.json";
import LoadingSpinner from "../../Components/loader/spinner";
import MetaD from "../../json/MetaData.json";
import "./nsstransition.css";

const Brand = React.lazy(() => import("../../Components/header/appheader"));
const Footer = React.lazy(() => import("../../Components/footer"));

export default function NssTransition() {
  const [nssProducts, setNssProducts] = useState([]);
  const [metaJson, setMetaJson] = React.useState([]);


  const fetchNssProducts = () => {
    const nssData = podData[0]?.products.find(
      (product) => product.id === "nss"
    );
    setNssProducts(nssData?.products || []); // Set NSS sub-products
  };

  const fetchMetaData = () => {
    const data = MetaD;
    // console.log("MetaD===", MetaD);

    // Filter metadata to match 'product' field in nssProducts
    const metaJsonVal = data.filter((item) =>
      nssProducts.some((product) => product.product === item.Product)
    );

    // console.log("Filtered metaJsonVal===", metaJsonVal);
    setMetaJson(metaJsonVal);
  };

  useEffect(() => {
    fetchNssProducts();
  }, []);

  useEffect(() => {
    if (nssProducts.length > 0) {
      fetchMetaData();
    }
  }, [nssProducts]);

  return (
    <Suspense fallback={<div className="text-center loadspinner"><LoadingSpinner /></div>}>
      <Container className="udp-hp-wrapper" maxWidth="false">
        <Brand />
        <div className="hp-body body-macro">
          <Grid container spacing={5} className="section-1">
            <Grid item className="left-box" sm={12} md={12} lg={6}>
            <Typography variant="h2">National Sample Survey</Typography>
            <p>
            The primary objective of the MIS was to collect data for generation of some important national indicators of Sustainable Development Goals (SDG). Along with that, considering the request from the Ministry of Housing and Urban Affairs (MoHUA), certain information on (i) Purchase/Construction of house(s)/flat(s) by the household for residential purpose after 31.03.2014 and (ii) Migration particulars were also collected. Additionally, some information on access to mass media, availability of birth registration certificates etc. was also collected through MIS.</p>
                
          
            </Grid>
            <Grid item className="right-box anim-text image-cloud" sm={12} md={12} lg={6}>
                              
                                  
            </Grid>
            
          </Grid>
        
          <Grid container className="section-2 macro2">
            {nssProducts.map((item, index) => (
              <Grid
                item
                className="theme-wrap grid ecbox"
                xs={12} sm={12} md={6} lg={6}
              
              >
                <div className="theme-pods">
                  <Link to={item.url} target="_blank">
                  <figure className="effect-dexter"
                    key={index}>
                    <figcaption>
                      <h5><span>{item.type}</span></h5>
                      <p>Click to see Dashboard</p>
                    </figcaption>
                  </figure>
                  </Link>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <Footer />
      </Container>
    </Suspense>
  );
}
