import React, { Suspense, useState, useEffect, useContext, useRef } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { useLocation, Link } from 'react-router-dom';
import './macroIndicators.css';
import axios from "axios";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { useParams  } from "react-router-dom";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MetaD from "../../json/MetaData.json";
import ProductD from "../../json/ProductsData.json";
import "../../css/userStyle.css";
import SwaggerUserCPI from '../../Components/swaggerUserCPI';
import SwaggerUserIIP from '../../Components/swaggerUserIIP';
import SwaggerUserASI from '../../Components/swaggerUserASI';
import SwaggerUserNAS from '../../Components/swaggerUserNAS';
import SwaggerUserPLFS from '../../Components/swaggerUserPLFS';
import SwaggerUserWPI from '../../Components/swaggerUserWPI';
import SwaggerUserNSS77 from '../../Components/swaggerUserNSS77';
import SwaggerUserNSS78 from '../../Components/swaggerUserNSS78';
import SwaggerUserASUSE from '../../Components/swaggerUserASUSE';
import LoadingSpinner from '../../Components/loader/spinner';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {Drawer, IconButton} from '@mui/material';
import {fetchRecords} from '../../framework/fetchRecordAPI';
import {downloadRecords} from '../../framework/downloadRecordAPI';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import PublicIcon from '@mui/icons-material/Public';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import TargetIcon from '@mui/icons-material/Adjust';
import BarChartIcon from '@mui/icons-material/BarChart';
// import PieChartIcon from '@mui/icons-material/PieChart';
import CloseIcon from '@mui/icons-material/Close';
import { DataCatalogueContext } from "../../globalContext";

let baseUrl = process.env.REACT_APP_SERVER_URL;
const DataFilters = React.lazy(()=> import('../../Components/Filters/dataFilters'))
const PaginatedFetchTable = React.lazy(()=> import('../../Components/ui/paginatedFetchTable'))
const Brand = React.lazy(() => import('../../Components/header/appheader'))
const Footer = React.lazy(()=> import('../../Components/footer/'))
const DataFilterViz = React.lazy(() => import("../../Components/Filters/dataFilterAsuseViz"));

export default function MacroPage() {
  const [value, setValue] = React.useState('1');
  const [pageData,setPageData] = React.useState(null);
  const [ProductData,setProductData] = React.useState(null);
  const [MetaData,setMetaData]= React.useState(null);
  const [metaJson,setMetaJson] = React.useState([]);
  const url = useLocation();
  const queryParams = new URLSearchParams(url.search);
  const productname = queryParams.get('product');
  const theme = useTheme();
  const {id} = useParams();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = React.useState(false);
  const [filterData, setFilterData] = React.useState(null)
  const [scrollToOrigin, setScrollToOrigin] = React.useState(false);
  const [tableView, setTableView] = React.useState('table');
  const { refIframe, selectedIndicatorViz, selectedFilterViz } =
  useContext(DataCatalogueContext);
  const [vizUrl, setVizUrl] = React.useState("");
  const [selectedVizType, setSelectedVizType] = useState("line");
  const [selectedViz, setSelectedViz] = useState("line");
  const iframeRef = useRef(null);

// console.log("refIframe===",refIframe)
  // Fetch dependent options based on the selected indicator
  useEffect(() => {
    setSelectedViz("line");
    if (selectedIndicatorViz) {
      const fetchVizOptions = async () => {
        try {
          const response = await axios.get(
            `${baseUrl}/asuse/getAsuseIndicatorList`
          );

          if (response.data && response.data.data) {
            const indicatorVizType = response.data.data
              .filter((item) => item.indicator_code === selectedIndicatorViz)
              .reverse()
              .pop();

            
            setSelectedVizType(indicatorVizType.viz);
          }
        } catch (error) {
          console.error("Error fetching dependent options:", error);
        }
      };
      fetchVizOptions();
    }
  }, [selectedIndicatorViz]);
  const vizIconMap = {
    map: <PublicIcon />,
    line: <LineAxisIcon />,
    hbar: <AlignHorizontalLeftIcon />,
    // pie: <PieChartIcon />,
    vbar: <BarChartIcon />,
    sunburst: <TargetIcon />,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  const setFiltersfortabledatafn = (filterData) => {
    setFilterData(filterData);
    setScrollToOrigin(!scrollToOrigin);
  };

  const fetchMetaData = async () => {

      const data = MetaD;
      const metaJsonVal = data.filter((item)=>item.Product==productname.toUpperCase());
      setMetaJson(metaJsonVal);
   
  };

  const fetchProductData = async () => {
    const data2 = ProductD;
  
    const filterData = data2[0].products.flatMap((item) => {
      // Check if the parent product matches
      const isProductMatch = item.product.trim().toLowerCase() === productname.trim().toLowerCase();

  
      // Check if any child products match
      if (Array.isArray(item.products)) {
        const matchedChild = item.products.find((child) => {
          return child.id.trim() === productname.trim();
        });
  
        if (matchedChild) {
          return [matchedChild]; // Return matched child product
        }
      }
  
      // Include parent product only if it matches
      return isProductMatch ? [item] : [];
    });
  
    setPageData(filterData);
  };
  

  
  const handleTabChange = (_event, value) => {
    setTableView(value);
  };

  const handleSelect = (item) => {
    //setSelectedItem(item);
    // console.log('Selected Item:', item); // You can do whatever you want with the data here
  };
  const handleVizIconClick = (SelectedType) =>{
    setSelectedViz(SelectedType);
 }

 const handleVizUrlParams = () => {
   let filterParams = "";
   if (selectedFilterViz) {
     Object.keys(selectedFilterViz).forEach((key) => {
       if (selectedFilterViz[key] && selectedFilterViz[key].length > 0) {
         let replaceKey = key.toLowerCase().replace(/\//g, '_');
         if (key === "year") {
           // Add state_ut without _code
           filterParams += `&${replaceKey}=${selectedFilterViz[key].join(",")}`;
         } else {
           // Add all other keys with _code suffix
           filterParams += `&${replaceKey}_code=${selectedFilterViz[key].join(",")}`;
         }
       }
     });

   }

   setVizUrl(
     `https://esankhyiki.mospi.gov.in/viz/asuse?viz_req=true&viz_type=${selectedViz}&indicator_code=${selectedIndicatorViz}${filterParams}`
    // `http://10.24.89.9:3000`

   );
 };

  React.useEffect(()=>{
    handleVizUrlParams();
    fetchMetaData();
    fetchProductData();
  }, [selectedFilterViz,selectedViz])

   return (
  <Suspense fallback={<div className='text-center loadspinner'><LoadingSpinner /></div>} className='bg-grey'>
       <Container className='udp-hp-wrapper bg-grey' maxWidth='false'> 
            <Brand />  
            {Array.isArray(pageData) && pageData.map((item,index)=>{

              return(
                <div className='innerpage-body fullwidth' key={index}>
                    <Typography variant='h5' className="macro-title" sx={{mb: '20px'}}>{item.title == "Establishments" ? "Economic Census" : item.title} {item.product=="EC4" || item.product=="EC5" || item.product=="EC6" || item.product=="NSS78" || item.product=="NSS77" ? "" : "(" + item.product + ")"}</Typography>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example" className="tablist">
                          {item.table && <Tab label="Table" value="1" />}
                          {item.viz && <Tab label="chart" value="2" />}
                          {item.swagger && <Tab label="API" value="3" />}
                          <Tab label="Metadata" value="4" />
                        </TabList>
                      </Box>
                      
                          <div>
                            <TabPanel value="1" className="tabpanel">
                              <div className='embedLoader-iframe macro-embed'>
                                {/* <iframe src={item.table} width="100%" frameBorder="0" ></iframe> */}
                                {item.table === 'react' ? (
                                    <div className="datatable-wrapper">
                                    <Drawer
                                        className="drawer-sidebar"
                                        variant={isMdUp ? "permanent" : "temporary"}
                                        classes={{
                                          paper: "paper-width",
                                        }}
                                        anchor="left"
                                        open={open}
                                        onClose={toggleDrawer}
										keepMounted
                                      >
                                        <div className="toolbar">
                                          <IconButton onClick={toggleDrawer} style={{ position: 'absolute', right: 0, top: 0 }}>
                                            <CloseIcon />
                                          </IconButton>
                                        </div>
                                        <div className='filter-mobile-sidebar'>
                                          <div className="parameter-data">Select Parameters to Get Data</div>
                                          <FormControl>
                                            <RadioGroup
                                              row
                                              aria-labelledby="demo-row-radio-buttons-group-label"
                                              name="row-radio-buttons-group"
                                              value={tableView}
                                              onChange={handleTabChange}
                                            >
                                              <FormControlLabel value="table" control={<Radio />} label="Tabular view" />
                                              <FormControlLabel value="grouped" control={<Radio />} label="Grouped view" />
                                            </RadioGroup>
                                          </FormControl>
                                          <DataFilters handleIndicator={handleSelect} setFiltersfortabledatafn={setFiltersfortabledatafn} />
                                        </div>
                                      </Drawer>
                                    <div className='filter-sidebar'>
                                        <div className="parameter-data">Select Parameters to Get Data</div>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={tableView} 
                                                onChange={handleTabChange}
                                            >
                                                <FormControlLabel value="table" control={<Radio />} label="Tabular view" />
                                                <FormControlLabel value="grouped" control={<Radio />} label="Grouped view" />
                                            </RadioGroup>
                                        </FormControl>
                                        <DataFilters setFiltersfortabledatafn={setFiltersfortabledatafn}  />
                                    </div>
                                    <main className="datatable-body">
                                      <Box>
                                        <IconButton
                                          color="inherit"
                                          aria-label="open drawer"
                                          edge="start"
                                          onClick={toggleDrawer}
                                          className="filter-btn"
                                        >
                                          <FilterAltIcon /> Filters
                                        </IconButton>
                                      </Box>
                                      <Box
                                        component="div" sx={{ mb: 2, maxWidth:'300px' }}
                                      >
                                      </Box>
                                      <PaginatedFetchTable handleTableView={tableView} fetchFunction={fetchRecords} downloadFunction={downloadRecords} fetchParams={{filterData: filterData, SECTION: id}}/>
                                    </main>
                                    </div>
                                ) : (
                                  <iframe src={item.table} width="100%" frameBorder="0" ></iframe>
                                )
                                }

                              </div>
                            </TabPanel>
                            <TabPanel value="2" className="tabpanel">
                              <div className="embedLoader-iframe macro-embed">
                                {item.vizreactfilter === "Y" ? (
                                  <div className="datatable-wrapper">
                                    <Drawer
                                      className="drawer-sidebar"
                                      variant={isMdUp ? "permanent" : "temporary"}
                                      classes={{
                                        paper: "paper-width",
                                      }}
                                      anchor="left"
                                      open={open}
                                      onClose={toggleDrawer}
									  keepMounted
                                    >
                                      <div className="toolbar">
                                        <IconButton
                                          onClick={toggleDrawer}
                                          style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </div>
                                      <div className="filter-mobile-sidebar">
                                        <div className="parameter-data">
                                          Select Parameters to Get Data
                                        </div>
                                        <FormControl>
                                          <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={tableView}
                                            onChange={handleTabChange}
                                          >
                                            <FormControlLabel
                                              value="table"
                                              control={<Radio />}
                                              label="Tabular view"
                                            />
                                            <FormControlLabel
                                              value="grouped"
                                              control={<Radio />}
                                              label="Grouped view"
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                        <DataFilterViz
                                          vizType={selectedViz}
                                          handleIndicator={handleSelect}
                                          setFiltersfortabledatafn={
                                            setFiltersfortabledatafn
                                          }
                                        />
                                      </div>
                                    </Drawer>
                                    <div className="filter-sidebar">
                                      <div className="parameter-data">
                                        Select Parameters to Get Data
                                      </div>

                                      <DataFilterViz
                                        vizType={selectedViz}
                                        setFiltersfortabledatafn={
                                          setFiltersfortabledatafn
                                        }
                                        refIframeContent={(iframe) => (iframeRef.current = iframe)}
                                      />
                                    </div>
                                    <main className="datatable-body">
                                      <Box>
                                        <IconButton
                                          color="inherit"
                                          aria-label="open drawer"
                                          edge="start"
                                          onClick={toggleDrawer}
                                          className="filter-btn"
                                        >
                                          <FilterAltIcon /> Filters
                                        </IconButton>
                                      </Box>
                                      <Box
                                        component="div"
                                        sx={{ mb: 2, maxWidth: "300px" }}
                                      ></Box>
                                      <div className="css-13omgl2">
                                        <div className="dataviz-wrapper">
                                          <div className="vizBtn">
                                            {selectedVizType?.split(",").map((type) => (
                                              <IconButton
                                                key={type}
                                                id={type}
                                                variant="outlined"
                                                onClick={()=>handleVizIconClick(type)}
                                              >
                                                {vizIconMap[type]}
                                              </IconButton>
                                            ))}
                                          </div>
                                          <iframe
                                            ref={iframeRef}
                                            src={vizUrl}
                                            width="100%"
                                            frameBorder="0"
                                          ></iframe>
                                        </div>
                                      </div>
                                    </main>
                                  </div>
                                ) : (
                                  <iframe
                                    src={item.viz}
                                    width="100%"
                                    frameBorder="0"
                                  ></iframe>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel value="3" className="tabpanel">
                              
                              <div className='embedLoader-iframe macro-embed'>
                              {item.id === 'cpi' && <SwaggerUserCPI />}
                              {item.id === 'iip' && <SwaggerUserIIP />}
                              {item.id === 'asi' && <SwaggerUserASI />}
                              {item.id === 'nas' && <SwaggerUserNAS />}
                              {item.id === 'plfs' && <SwaggerUserPLFS />}
                              {item.id === 'wpi' && <SwaggerUserWPI />}
                              {item.product === 'NSS77' && <SwaggerUserNSS77 />}
                              {item.product === 'NSS78' && <SwaggerUserNSS78 />}
                              {item.product === 'ASUSE' && <SwaggerUserASUSE />}
                              {(item.product === 'EC6' || item.product === 'EC5' || item.product === 'EC4') && <iframe src={item.swaggerLink} width="100%" frameBorder="0" ></iframe>}
                              </div>
                               
                            </TabPanel>
                            <TabPanel value="4" className="tabpanel">
                              <div className='embedLoader-iframe'>
                                {metaJson.length>0 && metaJson.map((item,index)=>(
                                    <ul className="gridtable" key={index}>
                                    <li><strong>Product:</strong> {item.title}</li>
                                    <li><strong>Category:</strong> {item.Category}</li>
                                    <li><strong>Geography:</strong> {item.Geography}</li>
                                    <li><strong>Frequency:</strong> {item.Frequency}</li>
                                    <li><strong>Time Period:</strong> {item.TimePeriod}</li>
                                    <li><strong>Data Source:</strong> {item.DataSource}</li>
                                    <li><strong>Description:</strong> {item.Description}</li>
                                    <li><strong>Last Updated Date:</strong> {item.lastUpdateDate}</li>
									                  { item.FutureRelease && (<li><strong>Future Release:</strong> {item.FutureRelease}</li>)}
                                    <li><strong>Base Period:</strong> {item.BasePeriod}</li>
                                    <li><strong>Key Statistics:</strong> {item.Keystatistics}</li>
                                    {
                                      item.Note && (<li><strong>Note:</strong> {item.Note}</li>)
                                    }
                                    {item.remarks  && 
                                      (<li><strong>Remarks:</strong> {item.remarks}</li>)}
                                    <li><strong>National Metadata Structure:</strong> {item.nmdslink && item.nmdslink !== "#" ? (
                                      <Link to={item.nmdslink} target="_blank" className="nmdslink">
                                        {item.NMDS} <svg x="0" y="0" viewBox="0 0 515.283 515.283">
                                          <g>
                                            <path d="M372.149 515.283H85.881c-22.941 0-44.507-8.934-60.727-25.155S.001 452.34.001 429.402V143.134c0-22.94 8.934-44.506 25.154-60.726s37.786-25.154 60.727-25.154h114.507c15.811 0 28.627 12.816 28.627 28.627s-12.816 28.627-28.627 28.627H85.881c-7.647 0-14.835 2.978-20.241 8.384s-8.385 12.595-8.385 20.242v286.268c0 7.647 2.978 14.835 8.385 20.243 5.406 5.405 12.594 8.384 20.241 8.384h286.267c7.647 0 14.835-2.978 20.242-8.386 5.406-5.406 8.384-12.595 8.384-20.242V314.895c0-15.811 12.817-28.626 28.628-28.626s28.628 12.816 28.628 28.626v114.507c0 22.94-8.934 44.505-25.155 60.727-16.221 16.22-37.788 25.154-60.726 25.154zm-171.76-171.762c-7.327 0-14.653-2.794-20.242-8.384-11.179-11.179-11.179-29.306 0-40.485L417.544 57.254H314.896c-15.811 0-28.626-12.816-28.626-28.627S299.085 0 314.896 0h171.761a28.542 28.542 0 0 1 19.997 8.144l.002.002.056.056.017.016.044.044.029.029.032.032.062.062.062.062.031.032.029.029a.62.62 0 0 1 .06.061l.056.057.002.002a28.55 28.55 0 0 1 8.144 19.998v171.761c0 15.811-12.817 28.627-28.628 28.627s-28.626-12.816-28.626-28.627V97.739l-237.4 237.399c-5.585 5.59-12.911 8.383-20.237 8.383z" fill="inherit" opacity="1" data-original="#000000"></path>
                                          </g>
                                        </svg>
                                      </Link>
                                    ) : (
                                      <span className="nmdslink-disabled">{item.NMDS}</span>
                                    )}</li>
                                    </ul>
                                ))}
                                  
                              </div>
                            </TabPanel>
                            
                          </div>
                      
                    </TabContext>
                </div>
             )
            })}
            <Footer />
        </Container>
     </Suspense>
   );
 }