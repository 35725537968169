import React, { Suspense, useState, useEffect } from "react";
import { Container, Grid, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import podData from "../../json/ProductsData.json";
import LoadingSpinner from "../../Components/loader/spinner";
import MetaD from "../../json/MetaData.json";
import "./economic.css";

const Brand = React.lazy(() => import("../../Components/header/appheader"));
const Footer = React.lazy(() => import("../../Components/footer"));

export default function EconomicCensus() {
  const [ecProducts, setEcProducts] = useState([]);
  const [metaJson, setMetaJson] = React.useState([]);

  const fetchEcProducts = () => {
    // Filter all products with id 'ec'
    const ecData = podData[0]?.products.filter(
      (product) => product.id === "ec"
    );
    // console.log("ecData===", ecData);
    const sortedData = ecData.sort((a, b) => a.period - b.period); // Customize sorting logic if it's not numeric
    console.log(sortedData)
    setEcProducts(sortedData || []); // Set EC sub-products
  };

  const fetchMetaData = () => {
    const data = MetaD;
    // console.log("MetaD===", MetaD);

    // Filter metadata to match 'product' field in ecProducts
    const metaJsonVal = data.filter((item) =>
      ecProducts.some((product) => product.product === item.Product)
    );

    // console.log("Filtered metaJsonVal===", metaJsonVal);
    setMetaJson(metaJsonVal);
  };

  useEffect(() => {
    fetchEcProducts();
  }, []);

  useEffect(() => {
    if (ecProducts.length > 0) {
      fetchMetaData();
    }
  }, [ecProducts]);

  return (
    <Suspense fallback={<div className="text-center loadspinner"><LoadingSpinner /></div>}>
      <Container className="udp-hp-wrapper" maxWidth="false">
        <Brand />
        <div className="hp-body body-macro">
          <Grid container spacing={5} className="section-1">
            <Grid item className="left-box" sm={12} md={12} lg={6}>
            <Typography variant="h2">ECONOMIC CENSUS</Typography>
            <p>
                The Economic Census provides the count of establishments involved in economic activities (excluding those engaged in crop production and plantation), including those in unorganized sector. It is a data source providing data on number of establishments in the country, number of persons employed therein along with their distribution by geographical location, type of ownership, etc. The Economic Census Dashboard is a single window approach to the previous Economic Censuses conducted in 1998, 2005 and 2013 for data visualisation on multiple indicators.
                </p>
                <p> 
                The Economic Census Dashboard is a repository of more than 130 million establishments.</p>
                
          
            </Grid>
            <Grid item className="right-box anim-text image-cloud" sm={12} md={12} lg={6}>
                              
                                  
            </Grid>
            
          </Grid>
          {/* <Grid container spacing={4}>
            {ecProducts.map((item, index) => {
              // Find the metadata for the current product
              const metaItem = metaJson.find((meta) => meta.Product === item.product);

              return (
                <Grid item xs={12} sm={6} md={12} key={item.id} className="transionpage-item">
                  <Card
                    variant="outlined"
                    sx={{
                      transition: "0.3s",
                      "&:hover": { boxShadow: 4, transform: "translateY(-5px)" },
                    }}
                  >
                    <Link
                      to={item.url}
                      target="_blank"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <CardContent className="nssbox">
                        <Grid container>
                          <Grid item xs={12} sm={6} md={4} className="box1 macropod">
                            <Typography variant="h6" component="span">
                              {index + 1}. {item.type}
                            </Typography>
                          </Grid>

                          {metaItem && (
                            <Grid item xs={12} sm={6} md={8} className="box2">
                              <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ mt: 1 }}
                              >
                                {metaItem.Description}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Link>
                  </Card>
                </Grid>
              );
            })}
          </Grid> */}
          <Grid container className="section-2 macro2">
            {ecProducts.map((item, index) => (
              <Grid
                item
                className="theme-wrap grid ecbox"
                xs={12} sm={12} md={4} lg={4}
              
              >
                <div className="theme-pods">
                  <Link to={item.url} target="_blank">
                  <figure className="effect-dexter"
                    key={index}>
                    <figcaption>
                      <h5><span>{item.type}</span></h5>
                      <p>Click to see Dashboard</p>
                    </figcaption>
                  </figure>
                  </Link>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
        <Footer />
      </Container>
    </Suspense>
  );
}
