import * as React from 'react';
import { useSearchParams } from "react-router-dom";
import { Checkbox, Container, FormControl, Grid, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Button } from '@mui/material';
import LoadingSpinner from '../../Components/loader/spinner';
import jsonData from '../../json/catalogdata.json';

const Brand = React.lazy(() => import('../../Components/header/appheader'))
const AutoCompleteSearch = React.lazy(() => import('../../Components/Catalogue/AutoCompleteSearch'))
const TableViewComp = React.lazy(() => import('../../Components/Catalogue/TableView'))
const GridViewComp = React.lazy(() => import('../../Components/Catalogue/GridView'))
const Footer = React.lazy(()=> import('../../Components/footer/'))
let finalFilterData;

export default function DataCatalogueView() {
    const viewType = "grid";
    const [searchparams, setSearchparams] = useSearchParams();
    const [filteredData, setFilteredData] = React.useState(null);
    const [yearList, setYearList] = React.useState([]);
    const [productList, setProductList] = React.useState([]);
    const getSelectedProductArray = searchparams.get('product') !== null && searchparams.get('product') !== '' ? searchparams.get('product').split(",") : [];
    const getSelectedYearArray = searchparams.get('year') !== null && searchparams.get('year') !== '' ? searchparams.get('year').split(",") : [];
    const [selectedProduct, setSelectedProduct] = React.useState(getSelectedProductArray);
    const [selectedYear, setSelectedYear] = React.useState(getSelectedYearArray);
    const [clearSearchValue, setClearSearchValue] = React.useState(false);
    const [resetFlag,setResetFlag] = React.useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const fetchRecords = async () => {

            let res= jsonData;
            if (res) {
                res = res.map(item => {
                    let extracted_years = [];
                    const yearRangeMatchArray = [...item.Refperiod.matchAll('[0-9]{4}-[0-9]{2}')];
                    const singleYearMatchArray = [...item.Refperiod.matchAll('[0-9]{4}')];
                    if (yearRangeMatchArray.length === 1) {
                        extracted_years = [
                            yearRangeMatchArray[0][0].substring(0, 4),
                            yearRangeMatchArray[0][0].substring(0, 2) + yearRangeMatchArray[0][0].substring(5,)
                        ];
                    } else if (singleYearMatchArray.length > 0) {
                        extracted_years = singleYearMatchArray.map(item => item[0]);
                    }
                    return { ...item, Year: extracted_years };
                });
                const yearArray = [...new Set(res.reduce((retVal, item) => {
                    return [...retVal, ...item.Year];
                }, []))].sort((a, b) => parseInt(b) - parseInt(a));
                const productArray = [...new Set(res.map(item => item.Product))].sort((a, b) => a.localeCompare(b));
                setYearList(yearArray);
                setProductList(productArray);
                res.sort((a, b) => {
                    const releaseDateComparison = getTimestamp(b.ReleaseDate) - getTimestamp(a.ReleaseDate);
                    if (releaseDateComparison !== 0) {
                        return releaseDateComparison;
                    } else {
                        return a.Product.localeCompare(b.Product);
                    }
                });
                finalFilterData=res;
                setFilteredData(res);
            } else {
                setYearList([]);
                setFilteredData([]);
            }
        
    }
    React.useEffect(() => {
       fetchRecords();
        
    }, []);

    const getTimestamp = (dateString) => {
        // Use Date.parse() or new Date() to convert the date string to a timestamp
        const timestamp = Date.parse(dateString);
        // If the timestamp is NaN (invalid date), return -1 or any appropriate value
        return isNaN(timestamp) ? -1 : timestamp;
    }

    const handleReset = (_event) => {
        fetchRecords();
        setSelectedProduct([]);
        setSelectedYear([]);
        setClearSearchValue(!clearSearchValue);
        searchparams.set("q", "");
        searchparams.set("year", []);
        searchparams.set("product", []);
        searchparams.set("page", 0);
        searchparams.set("search", "");
        setSearchparams(searchparams);

        if(searchparams.get("page")==0 && searchparams.get("search")!=='tags'){
            searchparams.set("search", "");
            searchparams.delete("q");
            searchparams.delete("year");
            searchparams.delete("product");
            setSearchparams(searchparams);
            setResetFlag(true);
        }
    };

    const handleYearChange = (event) => {
        if (!filteredData) return; // Prevent error if filteredData is null
    
        const year_value = event.target.value;
        searchparams.set("year", [...year_value]);
        searchparams.set("page", 0);
    
        let possibleProducts = filteredData
            ? filteredData.filter(item => (year_value.length === 0 || year_value.filter((yr) => item.Year.includes(yr)).length > 0)).map(item => item.Product)
            : [];
        
        possibleProducts = [...new Set(possibleProducts)].sort((a, b) => a.localeCompare(b));
    
        if (possibleProducts.length === 1) {
            handleProductChange({ target: { value: possibleProducts } });
        }
    
        setProductList(possibleProducts);
        setSearchparams(searchparams);
        setSelectedYear(year_value);
    };
    
    const handleProductChange = (event) => {
        const product_value = event.target.value;

        searchparams.set("product", [...product_value]);
        searchparams.set("page", 0);
        // ! [BEGIN] Code for inter-dependency between year and product
        let possibleYears = filteredData.filter(item => product_value.length === 0 || product_value.includes(item.Product)).reduce((retVal, item) => { return [...retVal, ...item.Year] }, []);
        possibleYears = [...new Set(possibleYears)].sort((a, b) => parseInt(b) - parseInt(a));

        // if (possibleYears.length === 1) {
        //     handleYearChange({ target: { value: possibleYears } });
        // }
        setYearList(possibleYears);
        // ! [END] Code for inter-dependency between year and product
        setSearchparams(searchparams);
        setSelectedProduct(product_value);
    };
    React.useEffect(() => {
       
        if(searchparams.get('product') !== null){
         let product_value = [];
             const product = searchparams.get('product');
             if (product) {
                 product_value.push(product);
             }

             searchparams.set("product", [...product_value]);
             searchparams.set("page", 0);
             // ! [BEGIN] Code for inter-dependency between year and product
             let possibleYears = finalFilterData && finalFilterData.filter(item => product_value.length === 0 || product_value.includes(item.Product)).reduce((retVal, item) => { return [...retVal, ...item.Year] }, []);
             possibleYears = [...new Set(possibleYears)].sort((a, b) => parseInt(b) - parseInt(a));
             
             if (possibleYears.length === 1) {
                 handleYearChange({ target: { value: possibleYears } });
             }
             setYearList(possibleYears);
             // ! [END] Code for inter-dependency between year and product
             setSearchparams(searchparams);
             setSelectedProduct(product_value);
        }
        
         
     }, [setFilteredData]);
     //console.log("filteredData==",filteredData)
    return (
        <Container className='udp-hp-wrapper bg-grey' maxWidth='false'>
            <Brand />
            <div style={{ paddingTop: "30px" }} className='innerpage-body'>
                {filteredData !== null && (
                    <Grid container className='catalogue-header'>
                        <Grid item className='catalogue-search'>
                            {filteredData !== null && (<div className='search-box'><AutoCompleteSearch dataArray={filteredData} clearSearchValue={clearSearchValue} resetFlag={resetFlag}/></div>)}
                        </Grid>
                        <Grid item className='sorting-box'>
                            <FormControl fullWidth>
                                <InputLabel id="filter-by-product">Filter By Product</InputLabel>
                                <Select
                                // disabled={searchparams.get('q') ? true:false}
                                    labelId="filter-by-product"
                                    id="filter-product"
                                    label="Filter By Product"
                                    multiple
                                    value={selectedProduct}
                                    onChange={handleProductChange}
                                    renderValue={(selected) => selected.join(", ")}
                                    MenuProps={MenuProps}
                                >
                                    {productList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <ListItemIcon>
                                                <Checkbox checked={selectedProduct.indexOf(option) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className='sorting-box'>
                            <FormControl fullWidth>
                                <InputLabel id="filter-by-year">Filter By Reference Period</InputLabel>
                                <Select
                                    //disabled={searchparams.get('q') ? true:false}
                                    labelId="filter-by-year"
                                    id="sort-year"
                                    label="Filter By Reference Period"
                                    multiple
                                    value={selectedYear}
                                    onChange={handleYearChange}
                                    renderValue={(selected) => selected.join(", ")}
                                    MenuProps={MenuProps}
                                >
                                    {yearList.map((option) => (
                                        <MenuItem key={option} value={option.toString()}>
                                            <ListItemIcon>
                                                <Checkbox checked={selectedYear.indexOf(option.toString()) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Button style={{ height: '2.76rem', marginBottom: '15px' }} variant='contained' onClick={handleReset}>
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                )}
                
                {filteredData === null && (
                    <div className='text-center' style={{ height: '300px', paddingTop: '150px' }}>
                        <LoadingSpinner />
                    </div>
                )}
                {filteredData !== null && viewType === "table" && (<TableViewComp data={filteredData} />)}
                {filteredData !== null && viewType === "grid" && (<GridViewComp data={filteredData} />)}
            </div>
            <Footer />
        </Container>
    )
}