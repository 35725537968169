import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { Link } from 'react-router-dom';

const SwaggerUIComponent = () => {
  const isInsideIframe = window.self !== window.top;



  return (
    <div  className='swagger-container'>
      <div className="title-wrap" style={isInsideIframe ? {marginTop: '0px'}:{ marginTop: '0px' }}>
        <h2 className={isInsideIframe ? "hide-in-iframe":"title-top"}>Wholesale Price Index (WPI)</h2>
        <i>Swagger UI is used here as a visualization tool for API documentation and will return the first 10 records only.</i>
        <div className="metadata">
          { <button className="btn authorize" ><Link to="../../API/WPI Metadata.xlsx" target="_blank" download><span> View MetaData</span></Link></button> }
        
          { <button className="btn authorize" ><Link to="../../API/WPI API User Manual.pdf" target="_blank" download><span> View User Manual</span></Link></button> }

        </div>

      
      </div>
        <SwaggerUI url="../../layout/swagger_user_wpi.yaml" />
        
      </div>
  );
}

export default SwaggerUIComponent;
