import axios from "axios"

const apiPath = process.env.REACT_APP_SERVER_URL

export const fetchRecords = async (fetchParams, PAGE_NUMBER, LIMIT) => {
    const {SECTION, filterData} = fetchParams
    const url = apiPath + "records"
    const result = await axios.get(url, { params: {SECTION, PAGE_NUMBER, LIMIT, filterData} });
    
    return result.data;
}
