import axios from "axios"

const apiPath = process.env.REACT_APP_SERVER_URL;

export const downloadRecords = async (fetchParams) => {
    const {SECTION, filterData, selectedColumns} = fetchParams
    const url = apiPath + "records/download"
    const result = await axios.get(url, { params: {SECTION, filterData, selectedColumns}, responseType: 'arraybuffer', headers: { 'Content-Type': 'blob' } });
    return result;
}
