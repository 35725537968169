import React, { useState, useEffect } from "react";
import { DataCatalogueContext } from "./globalContext";
import catlogJson from "./json/catalogdata.json";

export default function CatalogProvider(props) {
  const [selectedIndicator, setSelectedIndicator] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedIndicatorViz, setSelectedIndicatorViz] = useState(1);
  const [selectedFilterViz, setSelectedFilterViz] = useState(null);
  const [refIframe,setRefIframe] = useState(null);
  const [cpiCount, setCpiCount] = useState(null);
  const [iipCount, setIIPCount] = useState(null);
  const [nasCount, setNasCount] = useState(null);
  const [plfsCount, setPlfsCount] = useState(null);
  const [asiCount, setAsiCount] = useState(null);
  const [hcesCount, setHcesCount] = useState(null);
  const [misCount, setMisCount] = useState(null);
  const [asuseCount, setAsuseCount] = useState(null);
  const [camsCount, setcamsCount] = useState(null);
  const [wmiCount, setwmiCount] = useState(null);
  const [allCount, setAllCount] = useState(null);
  //console.log(catlogJson)

  const filterData = () => {
    let filtercpi = catlogJson.filter((item) => item.Product == "CPI");
    let filteriip = catlogJson.filter((item) => item.Product == "IIP");
    let filternas = catlogJson.filter((item) => item.Product == "NAS");
    let filterplfs = catlogJson.filter((item) => item.Product == "PLFS");
    let filterasi = catlogJson.filter((item) => item.Product == "ASI");
    let filterhces = catlogJson.filter((item) => item.Product == "HCES");
    let filtermis = catlogJson.filter((item) => item.Product == "MIS");
    let filterasuse = catlogJson.filter((item) => item.Product == "ASUSE");
    let filtercams = catlogJson.filter((item) => item.Product == "CAMS");
    let filterwmi = catlogJson.filter((item) => item.Product == "WMI");
    let filterall = catlogJson.filter((item) => item.Product);

    setCpiCount(filtercpi.length);
    setIIPCount(filteriip.length);
    setNasCount(filternas.length);
    setPlfsCount(filterplfs.length);
    setAsiCount(filterasi.length);
    setHcesCount(filterhces.length);
    setMisCount(filtermis.length);
    setAsuseCount(filterasuse.length);
    setcamsCount(filtercams.length);
    setwmiCount(filterwmi.length);
    setAllCount(filterall.length);
  };

  // Function to update the selected indicator
  const updateSelectedIndicator = (indicator) => {
    setSelectedIndicator(indicator);
    // console.log("Indicator updated:", indicator); // For debugging
  };
  const selectedFilterValues = (filterval) => {
    setSelectedFilter(filterval);
    // console.log("filterval updated:", filterval); // For debugging
  };
  // Function to update the selected indicator
  const updateSelectedIndicatorViz = (indicator) => {
    setSelectedIndicatorViz(indicator);
    // console.log("Indicator updated:", indicator); // For debugging
  };
  const selectedFilterValuesViz = (filterval) => {
    setSelectedFilterViz(filterval);
    // console.log("filterval updated:", filterval); // For debugging
  };
  const refIframeContent = (ref) => {
    setRefIframe(ref);
    // console.log("ref updated:", ref); // For debugging
  }
  useEffect(() => {
    filterData();
  }, []);

  return (
    <DataCatalogueContext.Provider
      value={{
        selectedIndicator,
        updateSelectedIndicator,
        selectedFilter,
        selectedFilterValues,
        selectedIndicatorViz,
        updateSelectedIndicatorViz,
        selectedFilterViz,
        selectedFilterValuesViz,
        refIframe,
        refIframeContent,
        cpiCount,
        iipCount,
        nasCount,
        plfsCount,
        asiCount,
        hcesCount,
        misCount,
        asuseCount,
        camsCount,
        wmiCount,
        allCount,
      }}
    >
      {props.children}
    </DataCatalogueContext.Provider>
  );
}
